<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Ronald van Oers</h2>
          <p>
            Na het afronden van het gymnasium kreeg ik de kans van mijn ouders
            om te gaan studeren. Dit werd de opleiding Fysiotherapie aan Avans
            Hogeschool in Breda. Dit was voor mij nog niet compleet genoeg. Om
            het menselijk lichaam nog beter te leren begrijpen en mensen te
            kunnen helpen, ben ik de studie Osteopathie in Gent gaan volgen. Ik
            vind het erg belangrijk om op zoek te gaan naar de oorzaak van
            klachten. Wanneer ik daar iets aan kan doen en klachten verdwijnen,
            dan geeft dit mij veel voldoening.
          </p>
          <p>
            Ik heb een sterke interesse in de samenhang van klachten en het zien
            van het groter geheel. Dit is natuurlijk osteopathie in een
            notendop! Na het afronden van deze studie heb ik ook mijn
            mastertitel gehaald.Daarnaast ben ik ook geïnteresseerd in
            wetenschap, voeding en sport.
          </p>
          <p>
            Om mijn kennis verder uit te breiden heb ik diverse nascholingen
            gedaan. Hierdoor kan ik mensen breder ondersteunen met bijvoorbeeld
            voeding en voedingssupplementen. Ook heb ik mij verder
            gespecialiseerd in mensen met chronische klachten. Wanneer mensen
            die al bij verschillende andere zorgverleners zijn geweest en ik kan
            ze met mijn kennis en ervaring helpen, daar doe ik het voor.
          </p>
          <p>
            Komend jaar wil ik mij verder verdiepen in CVS (Chronisch
            Vermoeidheidssyndroom). Hiervoor ga ik een cursus volgen over de
            Perrin methode, hierin staat het goed functioneren van lymfestelsel
            centraal.
          </p>
          <p>
            Om zelf fit en in beweging te blijven, tennis ik regelmatig en doe
            ik aan fitness.
          </p>
          <h3>Specialismen</h3>
          <p>
            In de praktijk kom ik veel mensen tegen met pijnklachten zoals
            rugpijn, hoofdpijn, nek-, schouder- en gewrichtsklachten.
          </p>
          <p>
            Door de mijn kennis en ervaring kan ik goed mensen met chronische
            klachten, zoals schildklierproblemen, fibromyalgie en prikkelbaar
            darmsysndroom, helpen.
          </p>
          <p>
            Juist mijn drang om te zoeken naar de oorzaak, zorgt ervoor dat ik
            mensen met complexe klachtenpatronen en ketens van klachten, kan
            helpen.
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>The Internation Academy of Osteopathy in Gent</li>
            <li>Allostase en osteopathie</li>
            <li>Geïntegreerde technieken volgens De Bakker</li>
            <li>Differentiaal diagnostiek cursussen</li>
            <li>Verschillende manipulatie cursussen</li>
            <li>Toegepaste fysiologie</li>
            <li>Fascia en osteopathie</li>
            <li>Orthomoleculaire voedingsleer</li>
            <li>Schildklierklachten en osteopathie</li>
            <li>Silver bullets</li>
            <li>Perfection in the management of the vertebral manipulation</li>
          </ul>

          <img src="../../assets/team/ronald.png" alt="Ronald" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
